import styled from "styled-components";
import {SpacerAuto, SpacerLarge, SpacerXLarge} from "../styles/globalStyles";
import {range} from "../Utils";
import React from "react";

import {Page, StyledRoundedLink, TextTitle} from "./Common";

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
export const AlphabetContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 2vw;
  width: 52vw;
  margin: 8px auto;

  @media (max-width: 600px) {
    flex: 0 0 6vw;
    width: 78vw;
    flex-wrap: wrap;
  }
`
export const AlphabetLetter = styled.div`
  font-size: 30px;
  //margin: 4px 8px;
  width: 2vw;
  text-align: center;

  @media (max-width: 600px) {
    width: 6vw;
  }
`
export const AlphabetPage = props => <Page {...props}>
    <SpacerLarge/>
    <TextTitle>
        font
    </TextTitle>
    <SpacerAuto/>
    <AlphabetContainer>
        {alphabet.map(l => <AlphabetLetter>{l}</AlphabetLetter>)}
    </AlphabetContainer>
    <AlphabetContainer>
        {alphabet.map(l => l.toUpperCase()).map(l => <AlphabetLetter>{l}</AlphabetLetter>)}
    </AlphabetContainer>
    <AlphabetContainer>
        {range(0, 10).map(l => l.toString()).map(l => <AlphabetLetter>{l}</AlphabetLetter>)}
        {range(10, 26).map(l => l.toString()).map(l => <AlphabetLetter/>)}
    </AlphabetContainer>
    <SpacerAuto/>
    <StyledRoundedLink href={"/config/Font.zip"} download>Download</StyledRoundedLink>
    <SpacerXLarge/>
</Page>