import React, {useEffect, useRef, useState} from "react";
import Navbar from "./Navbar";
import {Container, Screen, SpacerAuto, SpacerSmall, StyledRoundedButton} from "./styles/globalStyles";
import styled, {keyframes} from "styled-components";
import "react-simple-flex-grid/lib/main.css";

const TextTitle = styled.p`
    color: var(--primary-text);
    font-size: 40px;
    font-weight: 500;
    margin: 8px auto;
    max-width: 90vw;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const EllipticTextField = styled.input`
    width: 200px;
    height: 60px;
    padding: 10px;
    border: 3px solid var(--navbarcolor);
    border-radius: 30px;
    outline: none;
    text-align: center;
    font-size: 20px;
    box-sizing: border-box;
    margin: 8px auto;
    font-family: "bayformance", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    &:focus {
        border-color: #dedad8;
    }
`;

const CreateButton = styled(StyledRoundedButton)`
    font-size: 20px;
    padding: 5px 5px 8px 5px;
    margin: 8px auto;
`

const Link = styled(StyledRoundedButton.withComponent('a'))`
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
    width: fit-content;
    margin: 16px;

    ${({disabled}) => disabled && `
        pointer-events: none;
        background-color: #e0dad7;
        color: #918f8d;
    `}
`

const CardVideo = styled.video`
    border-radius: 45px;
    margin: auto;
    display: block;
    max-height: 40vh;
    width: 70.4vh;

    @media (max-width: 600px) {
        width: 90vw;
    }
`

const StyledRow = styled.div`
    width: 80vw;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;


    @media (max-width: 600px) {
        width: 100vw;
    }
`;

const rotate = keyframes`
    100% {
        transform: rotate(1turn);
    }
`

const Col = styled.div`
    border-radius: 16px;
    width: calc(100% / 7);
    @media (max-width: 600px) {
        width: calc(100% / 4);
    }
    padding: 3px;

    &.selected {
        position: relative;
        z-index: 0;
        overflow: hidden;
        border-radius: 15px;

        &::before {
            content: '';
            position: absolute;
            z-index: -2;
            left: -50%;
            top: -50%;
            width: 200%;
            height: 200%;
            background-color: #ecf0e9;
            background-repeat: no-repeat;
            background-size: 50% 50%, 50% 50%;
            background-position: 0 0, 100% 0, 100% 100%, 0 100%;
            background-image: linear-gradient(#646261, #bfbab7), linear-gradient(#bfbab7, #bfbab7), linear-gradient(#bfbab7, #bfbab7), linear-gradient(#bfbab7, #bfbab7);
            animation: ${rotate} 4s linear infinite;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 3px;
            top: 3px;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            background: #ecf0e9;
            border-radius: 15px;
        }
    }
`

const ClickableImage = styled.img`
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 4px 0;
    transform: translateY(-4px);
    border-radius: 15px;
`

const options = [
    {
        link: 'I am not Interested',
        layers: [
            {folder: "Base", prop: null, animation: 60, repeat: 1},
            {folder: "Body", prop: "body", animation: false},
            {folder: "Clothes", prop: "clothes", animation: false},
            {folder: "Eyes", prop: "eyes", animation: false},
            {folder: "Hats", prop: "hats", animation: false},
            {folder: "Mouth", prop: null, animation: [1.5, 1, 0.25, 0.25, 0.25, 0.25, 0.25, 1], repeat: 5},
        ],
        time: 5,
        sound: "I am not Interested.mp3",
        gifoverlay: "giftext.png",
        thumbnail: "Im not Interested.png"
    },
    {

        link: 'ready_ready',
        layers: [
            {folder: "Base", prop: null, animation: false},
            {folder: "Body", prop: "body", animation: false},
            {folder: "Clothes", prop: "clothes", animation: false},
            {folder: "Eyes", prop: "eyes", animation: false},
            {folder: "Hand", prop: "body", animation: 8, repeat: 6},
            {folder: "Hats", prop: "hats", animation: false},
            {folder: "Mouth", prop: null, animation: [0.3, 0.1, 0.1, 0.1, 0.1, 0.2, 0.2, 0.3, 0.6], repeat: 1},
        ],
        time: 2,
        sound: "Ready.mp3",
        gifoverlay: "giftext.png",
        thumbnail: "Ready.png"
    },
    {

        link: 'Nooooo',
        layers: [
            {folder: "Base", prop: null, animation: 48, repeat: 1},
            {folder: "Body", prop: "body", animation: false},
            {folder: "Clothes", prop: "clothes", animation: false},
            {folder: "Eyes", prop: "eyes", animation: false},
            {folder: "Hats", prop: "hats", animation: false},
            {folder: "Mouth", prop: null, animation: [0.166, 0.166, 0.166, 0.166, 0.166, 0.166, 0.1875, 0.1875, 0.1875, 0.1875, 0.1875, 0.1875, 0.1875, 0.1875, 0.1875, 0.4], repeat: 1},
        ],
        time: 3,
        sound: "No.mp3",
        gifoverlay: "giftext.png",
        thumbnail: "No.png"
    },
    {

        link: 'Holy Fuck',
        layers: [
            {folder: "Base", prop: null, animation: false},
            {folder: "Body", prop: "body", animation: false},
            {folder: "Clothes", prop: "clothes", animation: false},
            {folder: "Eyes", prop: null, animation: 3, repeat: 10},
            {folder: "Hats", prop: "hats", animation: false},
            {folder: "Mouth", prop: null, animation: [2, 0.75, 0.25, 2], repeat: 1},
        ],
        time: 5,
        sound: "Holy Fuck.mp3",
        gifoverlay: "giftext.png",
        thumbnail: "Holy.png"
    },
    {
        link: 'Boo',
        layers: [
            {folder: "Base", prop: null, animation: false},
            {folder: "Body", prop: "body", animation: false},
            {folder: "Clothes", prop: "clothes", animation: false},
            {folder: "Eyes", prop: "eyes", animation: false},
            {folder: "Hats", prop: "hats", animation: false},
            {folder: "Mouth", prop: null, animation: [1, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125], repeat: 2},
            {folder: "Rain", prop: null, animation: 42, repeat: 1},
        ],
        time: 5,
        sound: "boo.mp3",
        gifoverlay: "giftext.png",
        thumbnail: "Boo.png"
    },
    {
        link: "I aint reading",
        layers: [
            {folder: "Base", prop: null, animation: false},
            {folder: "Body", prop: "body", animation: false},
            {folder: "Clothes", prop: "clothes", animation: false},
            {folder: "Eyes", prop: "eyes", animation: false},
            {folder: "Hats", prop: "hats", animation: false},
            {folder: "Mouth", prop: null, animation: [0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25], repeat: 1},
        ],
        time: 5,
        sound: "Iaintreading.mp3",
        gifoverlay: "giftext.png",
        thumbnail: "IaintReading.png"
    },
    {
        link: 'OmNomNom',
        layers: [
            {folder: "Base", prop: null, animation: false},
            {folder: "Body", prop: "body", animation: false},
            {folder: "Clothes", prop: "clothes", animation: false},
            {folder: "Eyes", prop: "eyes", animation: false},
            {folder: "Hats", prop: "hats", animation: false},
            {folder: "Mouth", prop: null, animation: [0.5, 0.25, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125], repeat: 1},
        ],
        time: 3,
        sound: "OmNomNom.mp3",
        gifoverlay: "giftext.png",
        thumbnail: "OmNomNom.png"
    },
]

const special_ids = [0, 27, 58, 89, 981, 1214, 1568, 1801, 2100, 2656, 3405, 3810, 4267, 5239]

function Video() {
    const [tokenId, setTokenId] = useState("")
    const [vidLink, setVidLink] = useState("/config/black.mp4")
    const [gifLink, setGifLink] = useState("")
    const [downloadEnabled, setDownloadEnabled] = useState(false)
    const [value, setValue] = useState(null)

    const ref = useRef(null)

    const onMake = async () => {
        if (special_ids.indexOf(parseInt(tokenId)) !== -1) {
            setVidLink("/config/Legendary.mp4")
            setDownloadEnabled(false)
        } else {
            setVidLink(`/config/out/mp4/${value.link}/${tokenId}.mp4`)
            setGifLink(`/config/out/gif/${value.link}/${tokenId}.gif`)
            setDownloadEnabled(true)
        }
    }

    const handleTokenIdChanged = event => setTokenId(event.target.value)
    return <Screen>
        <Navbar/>
        <SpacerSmall/>
        <EllipticTextField type="text" placeholder="enter dob ID" onChange={handleTokenIdChanged} value={tokenId}/>
        <SpacerAuto/>
        <Container>
            <StyledRow>
                {options.map((opt, i) =>
                    <Col key={`opt_${i}`}
                         className={value === opt && "selected"}
                         onClick={() => setValue(opt)}>
                        <ClickableImage src={`/config/templates/${opt.link}/${opt.thumbnail}`}/>
                    </Col>
                )}
            </StyledRow>
        </Container>
        <SpacerAuto/>
        <CreateButton onClick={onMake}>dob me</CreateButton>
        <SpacerAuto/>
        <CardVideo
            controls={["/config/Legendary.mp4", "/config/black.mp4", "/config/LoadingNew.mp4"].indexOf(vidLink) === -1}
            autoPlay loop ref={ref}
            src={vidLink}/>
        <Container fd={"row"} jc={"center"}>
            <Link download disabled={!downloadEnabled} href={vidLink}>Download mp4</Link>
            <Link download disabled={!downloadEnabled} href={gifLink}>Download gif</Link>
        </Container>
        <SpacerAuto/>
    </Screen>;
}

export default Video
