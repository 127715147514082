import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: ${({bg}) => (bg ? bg : "#ecf0e9")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const Spacer = styled.div`
  height: ${({s}) => s};
  width: ${({s}) => s};
  flex-shrink: 0;
  @media (max-width: 600px) {
    height: ${({mb}) => mb === "auto" ? "1px" : mb};
    width: ${({mb}) => mb === "auto" ? "1px" : mb};
    margin: ${({mb}) => mb === "auto" ? "auto" : ""};;
    flex-grow: ${({mb_g}) => mb_g}
  }

`;
// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
  flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
  flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
  flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerXLarge = styled.div`
  height: 64px;
  width: 64px;
  flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerXXLarge = styled.div`
  height: 128px;
  width: 128px;
  flex-shrink: 0;
`;

export const SpacerAuto = styled.div`
  height: 1px;
  width: 1px;
  margin: auto;
  flex-shrink: 0;
`;

export const SpacerGrow = styled.div`
  height: 1px;
  width: 1px;
  flex-grow: ${({g}) => g};
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({flex}) => (flex ? flex : 0)};
  flex-direction: ${({fd}) => (fd ? fd : "column")};
  justify-content: ${({jc}) => (jc ? jc : "flex-start")};
  align-items: ${({ai}) => (ai ? ai : "flex-start")};
  background-color: ${({test}) => (test ? "pink" : "none")};
  width: ${({w}) => (w ? w : "100%")};
  ${({height}) => height && "height: " + height};
  background-image: ${({image}) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const Grid = styled.div`
  display: grid;
  justify-content: ${({jc}) => (jc ? jc : "flex-start")};
  align-items: ${({ai}) => (ai ? ai : "flex-start")};
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: ${({size}) => size || "22px"};
  font-weight: 500;
  margin: 8px;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: ${({color}) => color || "var(--primary-text)"};
  font-size: ${({fs}) => fs || "16px"};
  text-align: ${({ta}) => ta || "justify"};
  ${({margin}) => margin && ("margin: " + margin)}
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

// Used for providing a wrapper around a component
export const ImgOverlayContainer = styled.div`
  display: flex;
  flex: ${({flex}) => (flex ? flex : 0)};
  flex-direction: ${({fd}) => (fd ? fd : "column")};
  justify-content: ${({jc}) => (jc ? jc : "flex-start")};
  align-items: ${({ai}) => (ai ? ai : "flex-start")};
  background-color: ${({test}) => (test ? "pink" : "none")};
  background-image: ${({image}) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  transform: rotate(${({rt}) => (rt ? rt : 0)});
  position: absolute;
  top: 0;
  left: 0;
  padding: ${({pd}) => (pd ? pd : 0)};
  width: 100%;
  height: 100%;
`;

export const FooterImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
`

export const StyledButton = styled.button`
  padding: 10px;
  border: none;
  font-weight: bold;
  background-color: var(--accent);
  color: var(--accent-text);
  width: ${({width}) => width || "100px"};
  cursor: pointer;
  box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundedButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  font-family: inherit;
  //font-weight: bold;
  background-color: var(--accent);
  color: var(--accent-text);
  width: ${({width}) => width || "100px"};
  cursor: pointer;
  box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const StyledRoundedLink = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  font-weight: bold;
  background-color: var(--accent);
  color: var(--accent-text);
  width: ${({width}) => width || "100px"};
  cursor: pointer;
  box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--accent);
  color: var(--accent-text);
  font-weight: bold;
  font-size: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({margin}) => margin && `margin: ${margin};`}
  box-shadow: 0 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0 4px 0px -2px rgba(250, 250, 250, 0.3);

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s, height 0.5s;
`;

export const StyledRoundLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s, height 0.5s;
  border-radius: 100%;
`;

export const StyledRoundImg = styled.img`
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledHalfRoundImg = styled.img`
  border-radius: 5%;
  width: ${({width}) => width || "100%"};
  transition: width 0.5s;
`;

export const StyledRectangleImg = styled.img`
  width: ${({width}) => width || "100%"};
  transition: width 0.5s;
`;

export const UnstyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`;


export const Logo = styled.a`
  display: flex;
  width: ${({width}) => width || "50px"};
  height: 50px;
  background-image: url(${({img}) => img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: auto;
  cursor: pointer;

  &:hover {
    background-image: url(${({hover}) => hover});
  }

  &:active {
    background-image: url(${({click}) => click});
  }
`

export const AnimLogo = styled.a`
  display: flex;
  width: ${({width, size}) => width || size || "50px"};
  height: ${({height, size}) => height || size || "50px"};
  background-image: url(${({img}) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  cursor: pointer;

  &:hover {
    background-image: url(${({hover}) => hover});
  }

  &:active {
    background-image: url(${({click}) => click});
  }
`