export const sleep = time => new Promise(resolve => setTimeout(resolve, time));
/**
 * @param {number} start
 * @param {number} end
 *
 * @return {[number]}
 */
export const range = (start, end) => [...Array(end - start).keys()].map(i => i + start)

const gcd_two = (num1, num2) => {

    //Loop till both numbers are not equal
    while (num1 !== num2) {
        //check if num1 > num2
        if (num1 > num2) {
            //Subtract num2 from num1
            num1 = num1 - num2;
        } else {
            //Subtract num1 from num2
            num2 = num2 - num1;
        }
    }

    return num2;
}

const lcm_two = (a, b) => (a * b) / gcd_two(a, b);

/**
 *
 * @param nums {[int]}
 */
export const gcd = nums => nums.reduce((prev, curr) => gcd_two(prev, curr))

export const lcm = nums => nums.reduce((prev, curr) => lcm_two(prev, curr))
