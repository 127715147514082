import styled from "styled-components";
import {Container, Spacer, SpacerAuto} from "../styles/globalStyles";
import React from "react";

import {Page, StyledRoundedLink, TextSubtitle, TextTitle} from "./Common";

const CharacterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px auto;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: center;
    width: 45vw;
  }

`

const CharacterImg = styled.img`
  max-height: 50vh;
  max-width: 45vw;
  @media (max-width: 600px) {
    max-height: 30vh;
  }
`

const ColumnContainer = styled(Container)`
  margin: auto 8px;
`

const CharacterLink = styled(StyledRoundedLink)`
  @media (max-width: 600px) {
    margin: 8px auto;
    padding: 8px 12px;
    font-size: 20px;
  }
`

const CharacterColumn = ({name, img, link}) => <ColumnContainer>
    <TextSubtitle>{name}</TextSubtitle>
    <CharacterImg src={img}/>
    <CharacterLink href={link || img} download>download</CharacterLink>
</ColumnContainer>

export const CharacterPage = props => <Page {...props}>
    <Spacer s={"32px"} mb={"20px"}/>
    <TextTitle>
        character
    </TextTitle>
    <SpacerAuto/>
    <CharacterContainer>
        <CharacterColumn name={"2D dob"} img={"/config/images/character/2D dob.png"}/>
        <CharacterColumn name={"3D dob"} img={"/config/images/character/3D dob.png"} link={"/config/3Ddob.glb"}/>
    </CharacterContainer>
    <SpacerAuto/>
</Page>