import {SpacerGrow, SpacerLarge, SpacerXSmall} from "../styles/globalStyles";
import React from "react";

import {Bullet, Page, TextText, TextTitle} from "./Common";

export const ContentPage = props => <Page {...props}>
    <SpacerLarge/>
    <TextTitle>
        content
    </TextTitle>
    <SpacerGrow g={1}/>
    <TextText>
        <Bullet/>Creating good quality content is the most important thing for your brand
    </TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>You can use 2D/3D dob or just a mixture of our font and colors. You are limitless to create content
    </TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>People have adapted to dob's font, color palette, and line structure. We recommend that you consider
        these and
        create your brand by taking advantage of the resources we offer you
    </TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>You don't need to use a lot of hashtags/tags in your tweets. Simple and clean tweets can generate more
        interest!
    </TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>Memes are a great way to increase interest on social media. Keep this in mind!
    </TextText>
    <SpacerGrow g={2}/>

</Page>