import styled from "styled-components";
import {Container, Spacer, SpacerGrow} from "../styles/globalStyles";
import React from "react";

import {Page, TextSubsubtitle, TextText, TextTitle} from "./Common";


const CharacterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 8px auto;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
`

const CharacterImg = styled.img`
  border-radius: 16px;
  width: 500px;
  margin: 16px 64px;

  @media (max-width: 600px) {
    margin: 16px auto;
    width: 50vw;
  }
`

export const TwitterPage = props => <Page {...props}>
    <Spacer s={"24px"} mb={"12px"}/>
    <TextTitle>
        twitter
    </TextTitle>
    <SpacerGrow g={1}/>
    <TextText ta={"center"}>Twitter is a great way to start your brand</TextText>
    <TextText ta={"center"}>If you want to build a brand, you need to create an account that looks dope!</TextText>
    <SpacerGrow g={1}/>
    <CharacterContainer>
        <Container>
            <TextSubsubtitle mb_size={"25px"}>logo/banner</TextSubsubtitle>
            <TextText size={"25px"} mb_size={"15px"}>Be unique and clean, dob is all about simplicity, less is
                more!</TextText>
            <CharacterImg src={`/config/images/twitter/1.png`}/>
        </Container>

        <Container>
            <TextSubsubtitle mb_size={"25px"}>bio</TextSubsubtitle>
            <TextText size={"25px"} mb_size={"15px"}>Make it short but on point! Make sure that it explains what you are
                doing</TextText>
            <CharacterImg src={`/config/images/twitter/2.png`}/>
        </Container>
    </CharacterContainer>
    <SpacerGrow g={1}/>
</Page>