import React, {useEffect, useRef, useState} from "react";
import Navbar from "./Navbar";
import {Container, Screen, SpacerAuto, SpacerSmall, StyledRoundedButton} from "./styles/globalStyles";
import styled, {keyframes} from "styled-components";
import "react-simple-flex-grid/lib/main.css";

const TextTitle = styled.p`
    color: var(--primary-text);
    font-size: 40px;
    font-weight: 500;
    margin: 8px auto;
    max-width: 90vw;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

// noinspection CssUnusedSymbol
const Gdiv = styled.div`
    @media (max-width: 600px) {
        display: none;
    }

    #unity-container {
        margin: auto auto 0;
    }

    #unity-container.unity-desktop {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    #unity-container.unity-mobile {
        width: 100%;
        height: 100%
    }

    #unity-canvas {
        background: #000000;
        width: min(100vw, calc((100vh - 120px) * 960 / 600));
    }

    .unity-mobile #unity-canvas {
        width: 100%;
        height: 100%
    }

    #unity-loading-bar {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: none
    }

    #unity-logo {
        width: 154px;
        height: 130px;
        background: url('/TemplateData/unity-logo-dark.png') no-repeat center
    }

    #unity-progress-bar-empty {
        width: 141px;
        height: 18px;
        margin-top: 10px;
        background: url('/TemplateData/progress-bar-empty-dark.png') no-repeat center
    }

    #unity-progress-bar-full {
        width: 0%;
        height: 18px;
        margin-top: 10px;
        background: url('/TemplateData/progress-bar-full-dark.png') no-repeat center
    }

    #unity-footer {
        position: relative
    }

    .unity-mobile #unity-footer {
        display: none
    }

    #unity-webgl-logo {
        float: left;
        width: 204px;
        height: 38px;
    }

    #unity-build-title {
        float: right;
        margin-right: 10px;
        line-height: 38px;
        font-family: arial;
        font-size: 18px
    }

    #unity-fullscreen-button {
        float: right;
        width: 38px;
        height: 38px;
        background: url('/TemplateData/fullscreen-button.png') no-repeat center
    }

    #unity-warning {
        position: absolute;
        left: 50%;
        top: 5%;
        transform: translate(-50%);
        background: white;
        padding: 10px;
        display: none
    }
`

const MobileWarning = styled(TextTitle)`
    @media (min-width: 600px) {
        display: none;
    }
`

function doGame() {
    var container = document.querySelector("#unity-container");
    var canvas = document.querySelector("#unity-canvas");
    var loadingBar = document.querySelector("#unity-loading-bar");
    var progressBarFull = document.querySelector("#unity-progress-bar-full");
    var warningBanner = document.querySelector("#unity-warning");

    // Shows a temporary message banner/ribbon for a few seconds, or
    // a permanent error message on top of the canvas if type=='error'.
    // If type=='warning', a yellow highlight color is used.
    // Modify or remove this function to customize the visually presented
    // way that non-critical warnings and error messages are presented to the
    // user.
    function unityShowBanner(msg, type) {
        function updateBannerVisibility() {
            warningBanner.style.display = warningBanner.children.length ? 'block' : 'none';
        }

        var div = document.createElement('div');
        div.innerHTML = msg;
        warningBanner.appendChild(div);
        if (type == 'error') div.style = 'background: red; padding: 10px;';
        else {
            if (type == 'warning') div.style = 'background: yellow; padding: 10px;';
            setTimeout(function () {
                warningBanner.removeChild(div);
                updateBannerVisibility();
            }, 5000);
        }
        updateBannerVisibility();
    }

    var buildUrl = "Build";
    var loaderUrl = buildUrl + "/build.loader.js";
    var config = {
        dataUrl: buildUrl + "/build.data",
        frameworkUrl: buildUrl + "/build.framework.js",
        codeUrl: buildUrl + "/build.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "dob Studio",
        productName: "dob's Skate Shop",
        productVersion: "1.8",
        showBanner: unityShowBanner,
        webGLContextAttributes: {
            preserveDrawingBuffer: true,
        },
    };

    // By default Unity keeps WebGL canvas render target size matched with
    // the DOM size of the canvas element (scaled by window.devicePixelRatio)
    // Set this to false if you want to decouple this synchronization from
    // happening inside the engine, and you would instead like to size up
    // the canvas DOM size and WebGL render target sizes yourself.
    // config.matchWebGLToCanvasSize = false;

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        container.className = "unity-mobile";
        // Avoid draining fillrate performance on mobile devices,
        // and default/override low DPI mode on mobile browsers.
        config.devicePixelRatio = 1;
        unityShowBanner('WebGL builds are not supported on mobile devices.');
    }
    loadingBar.style.display = "block";

    var script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
        createUnityInstance(canvas, config, (progress) => {
            progressBarFull.style.width = 100 * progress + "%";
        }).then((unityInstance) => {
            loadingBar.style.display = "none";
        }).catch((message) => {
            console.log(message);
        });
    };
    document.body.appendChild(script);
}

function Video() {
    useEffect(() => {
        if (window.innerWidth > 600)
            doGame()
    }, [])
    return <Screen style={{height:'100vh'}}>
        <Navbar/>
        <SpacerAuto/>
        <MobileWarning>You can visit the website on PC to play the game!</MobileWarning>
        <Gdiv id="unity-container" style={{
            position: "relative",
            margin: "auto",
        }}>
            <canvas id="unity-canvas" width="960px" height="600px"></canvas>
            <Gdiv id="unity-loading-bar">
                <Gdiv id="unity-logo"></Gdiv>
                <Gdiv id="unity-progress-bar-empty">
                    <Gdiv id="unity-progress-bar-full"></Gdiv>
                </Gdiv>
            </Gdiv>
            <Gdiv id="unity-warning"> </Gdiv>
        </Gdiv>
        <SpacerAuto/>
    </Screen>;
}

export default Video
