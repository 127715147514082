import React from "react";


import Main from "./Main";
import SubDAO from "./SubDAO";
import WtfIsDob from "./WtfIsDob";
import DobIt from "./DobIt";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Brand from "./Brand/Brand";
import Video from "./Video";
import Game from "./Game";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Main/>}/>
                <Route exact path="/subdob/" element={<SubDAO/>}/>
                <Route exact path="/wtf_is_dob/" element={<WtfIsDob/>}/>
                <Route exact path="/dob_it/" element={<DobIt/>}/>
                <Route exact path="/dobme/" element={<Video/>}/>
                <Route exact path="/brand/" element={<Brand/>}/>
                <Route exact path="/skate/" element={<Game/>}/>

            </Routes>
        </BrowserRouter>
    );
}
