import React, {useRef} from "react";
import Navbar from "./Navbar";
import {StyledRectangleImg, Screen, SpacerLarge, Container} from "./styles/globalStyles";
import styled, {keyframes} from "styled-components";
import {range} from "./Utils";

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: ddd9d6;
  color: var(--primary-text);
  font-weight: bold;
  font-size: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({margin}) => margin && `margin: ${margin};`}

  :hover {
    background-color: var(--navbarcolor);
  }

  :active {
    background-color: var(--navbarcolor);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

//culture dream future movement style vibe
//culture love dream future movement life style vibe
const titleAnimation = keyframes`
  0.0% {
    content: "culture";
  }
  12.5% {
    content: "love";
  }
  25% {
    content: "dream";
  }
  37.5% {
    content: "future";
  }
  50% {
    content: "movement";
  }
  62.5% {
    content: "life";
  }
  75% {
    content: "style";
  }
  87.5% {
    content: "vibe";
  }
  100% {
    content: "culture";
  }
`

const AnimatedTitle = styled.p`
  color: var(--primary-text);
  font-size: 40px;
  font-weight: 500;
  max-width: 90vw;
  text-align: center;
  margin: 8px auto 0;

  &:before {
    animation: ${titleAnimation} 2s infinite;
    content: "culture";
  }
`

const animateHover = keyframes`
  0% {
    transform: translate(0, 0)
  }
  33% {
    transform: translate(1%, 1%)
  }
  66% {
    transform: translate(-0.3%, -0.3%)
  }
  99% {
    transform: translate(0, 0)
  }
`

const CarouselSpacer = styled.div`
  min-width: 8.3vw;
  height: 1px;
  @media (max-width: 600px) {
    min-width: 14.2vw;
  }
`
const ImgInner = styled(StyledRectangleImg)`
  border-radius: 24px;
    //animation: ${animateHover} 0.5s;

  &:hover {
    animation: ${animateHover} 0.8s forwards;
  }
`
const ImgOuter = styled.div`
  padding: 24px 32px;
  width: calc(41vw - 64px);
  height: fit-content;
  flex: 0 0 41vw;
  scroll-behavior: smooth;
  @media (max-width: 600px) {
    padding: 24px 8px;
    width: calc(71vw - 16px);
    flex: 0 0 71vw;
  }
`

const Outer = styled.div`
  overflow-y: hidden;
  width: 100vw;
`

const FlexContainer = styled.div`
  overflow-x: auto;
  padding-bottom: 17px;
  display: flex;
  flex-wrap: nowrap;
  cursor: grab;

  :active {
    cursor: grabbing;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`
const DobImg = props =>
    <ImgOuter>
        <ImgInner {...props}/>
    </ImgOuter>

const ButtonDiv = styled.div`
  margin-right: 24px;
  margin-top: -16px;
  background-color: #ddd9d6;
  border-radius: 500px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    display: none;
  }
`

function WtfIsDob() {
    const container = useRef(null)

    const imgWidth = window.innerWidth > 600 ? 0.41 : 0.71;
    const handleScrollRight = () => {
        container.current.scrollTo({
            left: container.current.scrollLeft + imgWidth * window.innerWidth,
            top: 0,
            behavior: "smooth"
        });
    }

    const handleScrollLeft = () => {
        container.current.scrollTo({
            left: container.current.scrollLeft - imgWidth * window.innerWidth,
            top: 0,
            behavior: "smooth"
        });
    }

    let mouseDown = false;
    let startX, scrollLeft;

    const handleMousedown = (e) => {
        mouseDown = true;
        startX = e.pageX - container.current.offsetLeft;
        scrollLeft = container.current.scrollLeft;
    }

    const handleMouseup = () => {
        mouseDown = false;
    }

    const handleMousemove = (e) => {
        e.preventDefault();
        if (!mouseDown) {
            return;
        }
        const x = e.pageX - container.current.offsetLeft;
        const scroll = x - startX;
        container.current.scrollLeft = scrollLeft - scroll;
    }

    return <Screen>
        <Navbar/>
        <SpacerLarge/>
        <AnimatedTitle/>
        <Container fd={"row"} jc={"flex-end"}>
            <ButtonDiv>
                <StyledRoundButton margin={"6px"} onClick={handleScrollLeft}>{"<"}</StyledRoundButton>
                <StyledRoundButton margin={"6px"} onClick={handleScrollRight}>{">"}</StyledRoundButton>
            </ButtonDiv>
        </Container>
        {window.innerWidth >= 600 ? 
        <Outer>
          <FlexContainer ref={container} onMouseDown={handleMousedown} onMouseUp={handleMouseup} onMouseMove={handleMousemove} onMouseLeave={handleMouseup}>
              <CarouselSpacer/>
              {window.innerWidth >= 600 ?
                  //web
                  range(1, 11)
                      .map(i => `config/images/${i}.jpg`)
                      .map(src => <DobImg src={src}/>) :
                  //mobil
                  range(1, 9)
                      .map(i => `config/images/${i}.jpg`)
                      .map(src => <DobImg src={src}/>)}
              <CarouselSpacer/>
          </FlexContainer>
        </Outer> : 
        <Outer>
          <FlexContainer ref={container} onMouseDown={handleMousedown} onMouseUp={handleMouseup} onMouseMove={handleMousemove} onMouseLeave={handleMouseup}>
              <CarouselSpacer/>
                  {
                  //mobil
                  range(1, 6)
                      .map(i => `config/images/${i}.jpg`)
                      .map(src => <DobImg src={src}/>)
                  }
              <CarouselSpacer/>
          </FlexContainer>
          <FlexContainer ref={container} onMouseDown={handleMousedown} onMouseUp={handleMouseup} onMouseMove={handleMousemove} onMouseLeave={handleMouseup}>
              <CarouselSpacer/>
                  {
                  //mobil
                  range(6, 11)
                      .map(i => `config/images/${i}.jpg`)
                      .map(src => <DobImg src={src}/>)
                  }
              <CarouselSpacer/>
          </FlexContainer>
        </Outer>    
        }

    </Screen>;
}

export default WtfIsDob