import React, {useState} from "react";
import * as s from "./styles/globalStyles";
import "react-simple-flex-grid/lib/main.css";
import styled from "styled-components";
import Navbar from "./Navbar";
import {Container, Logo, TextDescription, SpacerLarge} from "./styles/globalStyles";
import {Row, Col} from 'react-simple-flex-grid';
import Modal from 'react-modal';
import {twitter_gif, twitter_png} from "./Files";

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 40px;
  font-weight: 500;
  margin: 8px auto;
  max-width: 90vw;
  text-align: center;
`;

const StyledRow = styled(Row)`
  margin: 0 -16px;
  width: 80vw;
  margin: auto;
`;

const cardsData = [
    {
        id: 1, title: 'Naked Yeti Club', content: 'The DAO is lead by fully naked dobs aka Yetis', img: "/config/images/naked.png", twitter: "https://twitter.com/Naked_dob_DAO"
    },
    {
      id: 2, title: 'dob Studio', content: 'Welcome to dob Studio, where we bring the culture of 3D', img: "/config/images/dobstudio.jpg", twitter: "https://twitter.com/DobStudio"
    },
    {
        id: 2, title: 'The Watcher DAO', content: 'Sei Less Watch More, led by the Illuminati and Cloak', img: "/config/images/watcher.jpg", twitter: "https://twitter.com/thewatcherdao"
    },
    {
      id: 3, title: 'Lower Class Dob Club', content: 'The home for someone in need after a long day of work and an overtime shift', img: "/config/images/lcdc.jpg", twitter: "https://twitter.com/LowerClassDob"
    },
    
    // Add more cards if needed
];

const CardTitle = styled.h2`
  font-size: 1.5rem;
  margin: 16px;
`;

const CardImage = styled.img`
  border: 1px solid #cccccc;
  border-radius: 40px;
  height: 300px;
  width: 300px;
  margin: -1px;
  transition: 0.3s ease-in-out;
`

const CardContainer = styled.div`
  cursor: pointer;
  border: 1px solid #cccccc;
  margin: 32px auto;
  text-align: center;
  width: fit-content;
  border-radius: 40px;
  background-color: #efefef;
  transition: margin 0.3s ease-in-out;

  &:hover {
    margin: 27px auto;
  }

  &:hover ${CardImage} {
    width: 310px;
    height: 310px;
  }
`;

const Card = ({img, title, content, ...props}) => {
    return (
        <CardContainer {...props}>
            <CardImage src={img}/>
            <CardTitle>{title}</CardTitle>
        </CardContainer>
    );
};

const ModalCardContainer = styled.div`
  border: 2px solid #cccccc;
  margin: 32px auto;
  text-align: center;
  width: fit-content;
  border-radius: 40px;
  background-color: #efefef;
`;

const ModalCardTitle = styled.h2`
  font-size: 2rem;
  margin: 16px;
`;

const ModalCardImage = styled.img`
  border: 2px solid #cccccc;
  border-radius: 40px;
  width: min(400px, 90vw);
  margin: -2px;
`

const CardContent = styled.p`
  font-size: 1rem;
  margin: 1rem auto;
  width: min(250px, 75vw);
`;

const ModalCard = ({img, title, content, twitter, ...props}) => {
    return (
        <ModalCardContainer {...props}>
            <ModalCardImage src={img}/>
            <ModalCardTitle>{title}</ModalCardTitle>
            <CardContent>{content}</CardContent>
            <Logo
                style={{margin:"auto"}}
                target={"_blank"}
                img={twitter_gif}
                hover={twitter_png}
                click={twitter_png}
                href={twitter}
            />
        </ModalCardContainer>
    );
};

const ClubModal = styled(Modal)`
  width: fit-content !important;
  border: none !important;
  padding: 0 !important;
  background: none !important;
  margin: 20vh auto;
  outline: none
`

function SubDAO() {
    const [shownCard, setShownCard] = useState(null)

    function onCardClicked(card) {
        setShownCard(card)
    }

    return (<s.Screen>
        <ClubModal isOpen={!!shownCard} onRequestClose={() => setShownCard(null)} contentLabel={shownCard?.title}>
            <ModalCard {...shownCard}/>
        </ClubModal>
        <Navbar/>
        <SpacerLarge/>
        <TextTitle>break your walls, join sub-communities</TextTitle>
        <Container>
            <StyledRow>
                {cardsData.map((card) => (
                    <Col key={card.id} xs={12} sm={6} md={4}>
                        <Card title={card.title} content={card.content} img={card.img}
                              onClick={() => onCardClicked(card)}/>
                    </Col>
                ))}
            </StyledRow>
        </Container>
        <TextDescription margin={"32px 32px 64px"} ta={"center"} fs={"20px"}>
            If you created a sub-community, please fill out the form in our Discord to be featured
        </TextDescription>
    </s.Screen>);
}

export default SubDAO;
