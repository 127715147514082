import styled from "styled-components";
import {Container, Spacer, SpacerAuto, SpacerMedium, SpacerXLarge} from "../styles/globalStyles";
import React from "react";

import {Page, TextSubtitle, TextTitle} from "./Common";

const brightPalette = [
    "#a03e3e",
    "#d6772d",
    "#f3d552",
    "#539a74",
    "#3663ae",
    "#516685"]

const neutralPalette = [
    "#ece0ce",
    "#dbbfa0",
    "#ddd2c5",
    "#959595",
    "#6f6d6a",
    "#868686",
    "#513e37",
    "#493e3b",
    "#5b4b46",
    "#d5cec9",
    "#ede8e6",
    "#d2d0d0"
]

const PaletteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 700px;
  margin: 8px auto;

  @media (max-width: 600px) {
    width: 30vh;
    flex-wrap: wrap;
  }
`

const PaletteContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 700px;
  margin: 8px auto;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    width: min(40vh, 80vw);
  }
`

const ColorBlob = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${({color}) => color};
  border-radius: 100%;
  margin: 0 8px;

  @media (max-width: 600px) {
    width: 7vh;
    height: 7vh;
  }
`

const ColorText = styled.div`
  color: #313131;
  font-size: 20px;
  margin: 8px auto;
  max-width: 90vw;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 15px;
  }

`

const Color = ({color, ...props}) => <Container>
    <ColorBlob color={color}/>
    <ColorText>{color}</ColorText>
</Container>

export const PalettePage = props => <Page {...props}>
    <SpacerMedium/>
    <TextTitle>
        palette
    </TextTitle>
    <SpacerAuto/>
    <TextSubtitle>bright</TextSubtitle>
    <Spacer s={"24px"} mb={"8px"}/>
    <PaletteContainer>
        {brightPalette.map(color => <Color color={color}/>)}
    </PaletteContainer>
    <SpacerAuto/>
    <TextSubtitle>neutral</TextSubtitle>
    <Spacer s={"24px"} mb={"8px"}/>
    <PaletteContainer2>
        {neutralPalette.map(color => <Color color={color}/>)}
    </PaletteContainer2>
    <SpacerXLarge/>
    <SpacerAuto/>
</Page>