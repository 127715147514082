import React, {useEffect, useRef, useState} from "react";
import Navbar from "./Navbar";
import {Screen, SpacerXXLarge, SpacerLarge} from "./styles/globalStyles";
import styled from "styled-components";
import Select from "react-select";
import mergeImages from "./mergeImages";
import ImageScript from "./ImageScript";
import {just_png, loading_gif} from "./Files";
import {range, sleep} from "./Utils";

const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 40px;
  font-weight: 500;
  margin: 8px auto;
  max-width: 90vw;
  text-align: center;
`;

const EllipticTextField = styled.input`
  width: 200px;
  height: 60px;
  padding: 10px;
  border: 3px solid var(--navbarcolor);
  border-radius: 30px;
  outline: none;
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
  margin: 32px auto;

  &:focus {
    border-color: #dedad8;
  }
`;

const CardImage = styled.img`
  border-radius: 80px;
  width: min(400px, 90vw);
  margin: 0 auto;
`

const options = [
    {value: {link: 'gm', num: 7, speed: 100}, label: 'Gm'},
    {value: {link: 'coin', num: 18, speed:50}, label: 'Coin'},
    {value: {link: 'threadooor', num: 1, speed:50}, label: 'Threadooor'},
    {value: {link: 'basketball', num: 44, speed:25}, label: 'Basketball'},
    {value: {link: 'graffiti', num: 77, speed:60}, label: 'Graffiti'},
    {value: {link: 'cooking', num: 14, speed:80}, label: 'Cooking'},
    {value: {link: 'none'}, label: 'None'},
]

const StyledSelect = styled(Select)`
  margin: 32px auto;
  width: 50vw;
  font-size: 24px;
  color: #000
`

const special_ids = [0, 27, 58, 89, 981, 1214, 1568, 1801, 2100, 2656, 3405, 3810, 4267, 5239]

function DobIt() {
    const [tokenId, setTokenId] = useState("")
    const [image, setImage] = useState("/config/images/default840.png")
    const currTokenId = useRef();

    const [value, setValue] = useState(null);

    useEffect(() => {
        currTokenId.current = tokenId
    }, [tokenId]);

    useEffect(() => {
        const fn = async () => {
            let id = parseInt(tokenId)
            if (isNaN(id))
                return

            if (special_ids.indexOf(id) !== -1) {
                setImage(just_png)
                return
            }
            setImage(loading_gif)
            await sleep(500)
            const resp = await fetch("https://obpize4hlaq55vmcynzv3fuhf3zaquyahrohef2yfl72pu2alnba.arweave.net/cF6Mk4dYId7VgsNzXZaHLvIIUwA8XHIXWCr_p9NAW0I/" + id)
            const token = await resp.json()

            if (value && value.value.link !== "none") {
                const frames = await Promise.all(range(1, value.value.num + 1).map(async i => {
                    console.log(i)
                    const body_type = token?.attributes?.filter(({trait_type}) => trait_type === "body")?.at(0)?.value
                    const blob = await mergeImages([
                        {src: token.image, x: -80, y: 0},
                        {
                            src: `/config/templates/${value.value.link}/${body_type}/${i < 10 ? "0" + i : i}.png`,
                            x: 0,
                            y: 0
                        },
                    ].filter(a => a), {width: 840, height: 840, crossOrigin: "Anonymous", quality: 1, format: "png"})
                    const buffer = await blob.arrayBuffer()
                    return ImageScript.Frame.from(await ImageScript.Image.decode(buffer), value.value.speed)
                }))

                const gif = new ImageScript.GIF(frames)
                const arr = await gif.encode()
                const blob = new Blob([arr], {type: 'image/gif'})
                const url = URL.createObjectURL(blob)

                if (currTokenId.current === tokenId) {
                    setImage(url)
                }
            } else {
                const blob = await mergeImages(
                    [{src: token.image, x: -80, y: 0}],
                    {width: 840, height: 840, crossOrigin: "Anonymous", quality: 1, format: "png"})
                if (currTokenId.current === tokenId) {
                    setImage(URL.createObjectURL(blob))
                }
            }
        }
        fn()
    }, [tokenId, value]);

    const handleTokenIdChanged = event => setTokenId(event.target.value)
    return <Screen>
        <Navbar/>
        <SpacerLarge/>
        <TextTitle>do you need content? dob it</TextTitle>
        <EllipticTextField type="text" placeholder="search token ID" onChange={handleTokenIdChanged} value={tokenId}/>
        <CardImage src={image}/>
        <StyledSelect
            options={options}
            value={value}
            onChange={(newValue) => {
                setValue(newValue);
            }}
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    border: "2px solid #000",
                    color: "#000",
                }),
                dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    color: "#000"
                }),
                indicatorSeparator: (baseStyles) => ({
                    baseStyles,
                    color: "#fff"
                }),
            }}/>
        <SpacerXXLarge/>
    </Screen>;
}

export default DobIt