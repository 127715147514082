// Return Promise

/**
 *
 * @param {string[]} sources
 * @param {{crossOrigin: string}} options
 * @return {Promise<HTMLImageElement[]>}
 */
export const getImages = (sources, options) =>
    Promise.all(
        sources.map(source => new Promise((resolve, reject) => {
            // Convert sources to objects
            if (source.constructor.name !== 'Object') {
                source = {src: source};
            }

            // Resolve source and img when loaded
            const img = new Image();
            img.crossOrigin = options.crossOrigin;
            img.onerror = () => reject(new Error('Couldn\'t load image'));
            img.onload = () => resolve(Object.assign({}, source, {img}));
            img.src = source.src;
        })));

/**
 *
 * @param {[{src: string, x: int, y: int} | string]} sources
 * @param options
 * @return {Promise<Blob>}
 */
const mergeImages = (sources = [], options = {}) => new Promise(resolve => {
    // Setup browser/Node.js specific variables
    const canvas = options.Canvas ? new options.Canvas() : window.document.createElement('canvas');
    const Image = options.Image || window.Image;

    // Load sources
    const images = getImages(sources, options)

    // Get canvas context
    const ctx = canvas.getContext('2d');

    // When sources have loaded
    images
        .then(images => {
            // Set canvas dimensions
            const getSize = dim => options[dim] || Math.max(...images.map(image => image.img[dim]));
            canvas.width = getSize('width');
            canvas.height = getSize('height');
            console.log(canvas.width)
            console.log(canvas.height)

            // Draw images to canvas
            images.forEach(image => {
                ctx.globalAlpha = image.opacity ? image.opacity : 1;
                return ctx.drawImage(image.img, image.x || 0, image.y || 0);
            });

            // Resolve all other data URIs sync
            canvas.toBlob(async blob => {
                resolve(blob)
            }, options.format, options.quality)
        });
});

export default mergeImages