import styled from "styled-components";
import {Container, SpacerAuto, SpacerLarge} from "../styles/globalStyles";
import React from "react";

import {Bullet, Page, TextSubsubtitle, TextText, TextTitle} from "./Common";


const CharacterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 8px auto;
  flex-wrap: wrap;
`

const CharacterImg = styled.img`
  border-radius: 100%;
  width: 300px;
  margin: 16px 64px;

  @media (max-width: 600px) {
    width: 25vw;
    margin: 8px;
  }
`

const CharacterColumn = ({name, ...props}) => <Container {...props}>
    <TextSubsubtitle mb_size={"25px"}>{name}</TextSubsubtitle>
    <CharacterImg src={`/config/images/create your idea/${name}.png`}/>
</Container>


export const IdeaPage = props => <Page {...props}>
    <SpacerLarge/>
    <TextTitle>
        create your idea
    </TextTitle>
    <SpacerAuto/>
    <TextText><Bullet/>It could be Digital or Physical</TextText>
    <TextText><Bullet/>It can be related to a talent that you have and are ready to go as a solo creator or it can be a
        group
        work. dob has a lot of creators, entrepreneurs, and builders, you can make new friendships and work
        together</TextText>
    <TextText><Bullet/>Here are some examples that you can do anything under dob with your idea</TextText>
    <SpacerAuto/>
    <CharacterContainer>
        {["club", "digital", "physical"].map(i => <CharacterColumn name={i}/>)}
    </CharacterContainer>
    <SpacerAuto/>
</Page>