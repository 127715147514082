import styled from "styled-components";
import React from "react";

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 110px;
  font-weight: 500;
  margin: 8px auto;
  max-width: 90vw;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 50px;
  }
`;


export const TextDob = styled.p`
  color: var(--primary-text);
  font-size: 80px;
  font-weight: 500;
  margin: 8px auto;
  max-width: 90vw;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 50px;
  }
`;


export const TextSubtitle = styled.p`
  color: var(--primary-text);
  font-size: 60px;
  font-weight: 500;
  margin: 8px auto;
  max-width: 90vw;
  text-align: center;


  @media (max-width: 600px) {
    font-size: 35px;
  }
`;


export const TextSubsubtitle = styled.p`
  color: var(--primary-text);
  font-size: 40px;
  font-weight: 500;
  margin: 8px auto;
  max-width: 90vw;
  text-align: center;

  @media (max-width: 600px) {
    font-size: ${({mb_size}) => mb_size || "30px"};
  }
`;


export const TextText = styled.p`
  color: var(--primary-text);
  font-size: ${({size}) => size || "30px"};
  margin: 8px auto;
  width: ${({w}) => w || "85%"};
  text-align: ${({ta}) => ta || "justify"};

  @media (max-width: 600px) {
    font-size: ${({mb_size}) => mb_size || "20px"};
  }
`;

export const LinkSubsubtitle = styled(TextSubsubtitle).attrs({as: 'a'})`
  text-decoration: none;
`

export const Page = styled.div`
  min-height: 100vh;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
`
export const StyledRoundedLink = styled.a`
  padding: 20px 40px;
  border-radius: 50px;
  border: none;
  font-size: 40px;
  background-color: var(--accent);
  color: var(--accent-text);
  box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
  text-decoration: none;

  margin: 16px auto;

  :active {
    box-shadow: none;
  }

  @media (max-width: 600px) {
    padding: 15px 20px;
    font-size: 27px;
  }

`;
export const Bullet = () => <span style={{marginRight: "15px"}}>•</span>