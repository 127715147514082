import React, {useEffect, useRef, useState} from "react";
import * as s from "./styles/globalStyles";
import "react-simple-flex-grid/lib/main.css";
import styled from "styled-components";
import Navbar from "./Navbar";
import {Container} from "./styles/globalStyles";
import {
    break_c_gif,
    break_gif,
    break_h_gif
} from "./Files";
import {useLocation} from "react-router-dom";

export const CAnimLogo = styled.a`
  display: flex;
  width: ${({width, size}) => width || size || "50px"};
  height: ${({height, size}) => height || size || "50px"};
  background-image: url(${({img}) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 200ms;

  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 256;

  &:hover {
    background-image: url(${({hover}) => hover});
  }

  &:active {
    background-image: url(${({click}) => click});
  }

  &.hidden {
    opacity: 0;
    background-image: url(${({click}) => click});
    cursor: default;
  }

  &.gone {
    display: none;
  }
`

const BoomVideo = styled.video`
  position: absolute;
  bottom: 0;
  right: 0;
  height: max(100vh, 57vw);
  width: max(100vw, 178vh);
  background-color: ${({bg}) => bg || "none"};
  margin: 0;
  z-index: 255;
  ${(({hidden}) => hidden ? "display: none" : "")};
`

export const MiddleImg = styled.img`
  width: max(40vw, 350px);
  margin: auto;
`

const AllImg = styled.img`
  max-height: calc(100vh - 112px);
  max-width: 100vw;
  margin: auto;
`

const MyScreen = styled(s.Screen)`
  height: 100vh;
  overflow: hidden;
`

function Main() {
    const location = useLocation();
    const skipVideo = location.state
        || document.referrer.includes(document.location.host)
        || window.innerWidth < 600;

    const [vidEnded, setVidEnded] = useState(skipVideo)
    const [vidIsPlaying, setVidIsPlaying] = useState(skipVideo)
    const vidRef = useRef(null);

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            console.log("resize!")
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (vidIsPlaying && !skipVideo) {
            vidRef.current.play();
            vidRef.current.onended = () => {
                setVidEnded(true);
            }
        }
    }, [vidIsPlaying])

    return (<MyScreen  bg={windowSize > 600 ? "#ecf0e9" : "#e8ede5"}>
        <CAnimLogo className={`${vidIsPlaying ? "hidden" : ""} ${vidEnded ? "gone" : ""}`}
                   img={break_gif}
                   hover={break_h_gif}
                   click={break_c_gif}
                   width={"225px"}
                   height={"120px"}
                   onClick={() => setVidIsPlaying(true)}
        />

        <Navbar/>
        <BoomVideo
            hidden={vidEnded}
            bg={vidIsPlaying ? "none" : "#ecf0e9"}
            preload="auto"
            playsInline
            ref={vidRef}
            src={windowSize > 600 ? "/config/w.webm" : "/config/smallboom.mov"} type="video/webm"
        />
        {window.innerWidth >= 600 ?
            //web
            <Container fd={"column"} flex={1}>
                <AllImg src={"/config/images/main_bg.gif"}/>
            </Container> :
            //mobil
            <Container fd={"column"} flex={1}>
                <MiddleImg src={"/config/images/fuck.gif"}/>
            </Container>}
    </MyScreen>);
}

export default Main;
