import styled from "styled-components";
import {SpacerAuto, SpacerLarge, SpacerMedium} from "../styles/globalStyles";
import React from "react";

import {Bullet, LinkSubsubtitle, Page, TextText, TextTitle} from "./Common";

const DobImage = styled.img`
  flex-basis: 0;
  flex-grow: 4;
  height: 0;
  max-height: min(calc(100vw - 32px), 40vh);
  margin: 0 auto;
  border-radius: 100%;
`

const TextParent = styled.div`
  width: fit-content;
  margin: 0 auto;
`

export const ForeverPage = props => <Page {...props}>
    <SpacerLarge/>
    <TextTitle>
        find your forever dob
    </TextTitle>
    <SpacerAuto/>

    <TextParent>
        <TextText><Bullet/>It will be the center of your brand</TextText>
        <TextText><Bullet/>You can be sure to find a dob that will represent your brand</TextText>
        <TextText><Bullet/>There are multiple designs of principles and color theories on dob. We carefully curated the
            collection</TextText>
    </TextParent>

    <SpacerAuto/>
    <LinkSubsubtitle href={"https://pallet.exchange/collection/dob"} target={"_blank"}>click to find</LinkSubsubtitle>
    <SpacerLarge/>
    <DobImage src={"/config/images/find your forever dob/findyourforeverdob.gif"}/>
    <SpacerMedium/>
</Page>