import React from "react";
import * as s from "../styles/globalStyles";
import "react-simple-flex-grid/lib/main.css";
import styled from "styled-components";
import {AlphabetPage} from "./AlphabetPage";
import {PalettePage} from "./PalettePage";
import {Page, TextTitle} from "./Common";
import {CharacterPage} from "./CharacterPage";
import Navbar from "../Navbar";
import {ForeverPage} from "./ForeverPage";
import {IdeaPage} from "./IdeaPage";
import {TwitterPage} from "./TwitterPage";
import {ContentPage} from "./ContentPage";
import {MarketingPage} from "./MarketingPage";

const SnapScreen = styled(s.Screen)`
  display: block;
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
`

const FirstPageTitle = styled(TextTitle)`
  margin: calc(45vh - 112px) auto 0;
  @media (max-width: 600px) {
    margin: calc(45vh - 112px) auto 0;
  }
`

const CenteredPageTitle = styled(TextTitle)`
  margin: auto;
`


function Brand() {
    return (<SnapScreen>
        <Page>
            <Navbar/>
            <FirstPageTitle>build your own brand with dob</FirstPageTitle>
        </Page>
        <AlphabetPage/>
        <PalettePage/>
        <CharacterPage/>
        <Page>
            <CenteredPageTitle>ultimate guide for your dob brand</CenteredPageTitle>
        </Page>
        <ForeverPage/>
        <IdeaPage/>
        <TwitterPage/>
        <ContentPage/>
        <MarketingPage/>
        <Page>
            <CenteredPageTitle>welcome to dob, let's break the wall!</CenteredPageTitle>
        </Page>
    </SnapScreen>);
}

export default Brand;
