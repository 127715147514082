import {SpacerGrow, SpacerLarge, SpacerXSmall} from "../styles/globalStyles";
import React from "react";

import {Bullet, Page, TextText, TextTitle} from "./Common";

export const MarketingPage = props => <Page {...props}>
    <SpacerLarge/>
    <TextTitle>
        marketing
    </TextTitle>
    <SpacerGrow g={1}/>
    <TextText>
        <Bullet/>dob is family </TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>You can create a group chat or make connections to boost your engagement. Come to Discord or DM
        other dobs, you'll see how every one of dobs is supportive
    </TextText>
    <SpacerXSmall/>
    <TextText><Bullet/>Consistency is key</TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>Try to share something every day or two days. You'll be rewarded by being around and
        Twitter will push you in front of more people
    </TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>We are here to support you </TextText>
    <SpacerXSmall/>
    <TextText>
        <Bullet/>As dob creators, we would like to push you forward to help your marketing on
        Twitter and feature your brand on our website. When your brand is ready to go, reach out to us!
    </TextText>
    <SpacerGrow g={2}/>
</Page>