import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {Link} from "react-router-dom";
import Hamburger from "hamburger-react";

const Bar = styled.nav`
    font-size: 18px;
    ${({displayNav}) => displayNav ? "background: #f5f6f2" : ""};

    max-height: 100vh;
    @media (min-width: 768px) {
        overflow: visible;
        display: flex;
        justify-content: space-between;
        padding: 16px 32px;
        align-items: center;
        position: relative;
    }
    @media (max-width: 768px) {
        overflow: clip;
        padding: 16px;
        margin: 0 0 0 8px;
    }
`

const showNav = keyframes`
    0% {
        height: 0;
        opacity: 0;
    }
    1% {
        padding: 80px 16px 16px;
        height: 100vh;
        opacity: 0;
    }
    100% {
        padding: 80px 16px 16px;
        height: 100vh;
        opacity: 1;
    }
`

const hideNav = keyframes`
    0% {
        padding: 80px 16px 16px;
        height: 100vh;
        opacity: 1;
    }
    99% {
        padding: 80px 16px 16px;
        height: 100vh;
        opacity: 0;
    }
    100% {
        height: 0;
        opacity: 0;
    }
`

const MainNav = styled.ul`
    list-style-type: none;
    display: flex;
    //margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: 768px) {
        background: #f5f6f2;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        opacity: 0;
        width: 100vw;
        z-index: 1;
    }

    &.show {
        @media (max-width: 768px) {
            animation: ${showNav} 0.8s forwards;
        }
    }

    &.hide {
        @media (max-width: 768px) {
            animation: ${hideNav} 0.8s forwards;
        }
    }
`

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    flex-direction: column;
    padding-top: 40px;
    transform: translateY(-40px);
    z-index: 1000;

    &:hover {
        display: flex;
    }
`

const NavLi = styled.li`
    align-content: end;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    position: relative;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: space-around;
        border-bottom: 1px solid #aaa;
    }

    &:first-child {
        @media (max-width: 768px) {
            border-top: 1px solid #aaa;
            margin-top: 8px;
        }
    }

    &:hover ${DropdownContent} {
        display: flex;
    }
`


const NavBarToggle = styled.span`
    @media (min-width: 768px) {
        display: none;
    }
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 255;
`

const TitleLink = styled(Link)`
    color: var(--primary-text);
    font-size: 40px;
    line-height: 1.6;
    text-decoration: none;
    z-index: 10;
    position: relative;

    @media (min-width: 768px) {
        font-size: 70px;
        line-height: 1;
        padding-bottom: 10px;
    }
`;

const NavLink = styled(Link)`
    color: var(--primary-text);
    font-size: ${({size}) => size || "25px"};
    line-height: 1.6;
    text-decoration: none;
    z-index: 10;
    position: relative;
`;

const HighlightSpan = styled.span`
    width: ${({width}) => width}px;
    height: 40px;
    background-color: var(--navbarcolor);
    z-index: 3;
    position: absolute;
    left: 0;
    transform: translate(${({left}) => left}px, 0);
    transition: ${({visible}) => visible ? "0.3s ease-in-out" : "none"};
    display: ${({visible}) => visible ? "block" : "none"};
    border-radius: 25px;

    @media (max-width: 768px) {
        display: none;
    }
`

const pages = [
    {label: "wtf is dob", link: "/wtf_is_dob"},
    {label: "brand", link: "/brand"},
    {label: "dob it", link: "/dob_it"},
    {label: "dob me", link: "/dobme"},
    {label: "skate", link: "/skate"},
    {label: "subdob", link: "/subdob"},
    {
        label: "socials", tree: [
            {label: "twitter", link: "https://twitter.com/dobnfts", newtab: true},
            {label: "discord", link: "https://discord.gg/dobnfts", newtab: true},
            {label: "join dob", link: "https://pallet.exchange/collection/dob", newtab: true},
        ]
    },
]

function Navbar({className}) {
    const [highlight, setHighlight] = useState({width: 0, left: 0, visible: false})
    let [displayNav, setDisplayNav] = useState("")

    const pageRefs = useRef(pages.map(() => null))

    const getCurrentPageHighlight = () => {
        return pages
            .map((el, i) => ({...el, ref: pageRefs.current[i]}))
            .filter(({link, tree}) =>
                link === window.location.pathname ||
                (tree && !tree.every(({link}) => link !== window.location.pathname)))
            .map(({ref}) => ref.getBoundingClientRect())
            .map(rect => ({width: rect.width, left: rect.x, visible: true}))
            .at(0) || {width: 0, left: 0, visible: false}
    }

    window.onload = () => {
        setHighlight(getCurrentPageHighlight());
    }

    useEffect(() => {
        setHighlight(getCurrentPageHighlight());
    }, []);


    const handleHighlight = e => {
        let rect = e.currentTarget.getBoundingClientRect()
        setHighlight({width: rect.width, left: rect.x, visible: true})
    }
    const handleHighlightEnd = () => setHighlight(getCurrentPageHighlight())

    const handleToggle = () => setDisplayNav(displayNav === "show" ? "hide" : "show")

    return <Bar className={className} displayNav={displayNav === "show"}>
        <NavBarToggle>
            <Hamburger color={"black"} toggled={displayNav === "show"} toggle={handleToggle}/>
        </NavBarToggle>
        <TitleLink state={"intl"} to={"/"}>dob</TitleLink>
        <MainNav className={displayNav} onMouseLeave={handleHighlightEnd}>
            {pages.map(({label, link, newtab, tree}, i) =>
                <NavLi key={i} ref={el => pageRefs.current[i] = el} onMouseEnter={handleHighlight}>
                    <div>
                        <NavLink target={newtab && "_blank"} state={"intl"} to={link}>
                            {label}
                        </NavLink>
                        {tree && <DropdownContent>
                            {tree.map(({label, link, newtab}) =>
                                <NavLink target={newtab && "_blank"} state={"intl"} to={link}>
                                    {label}
                                </NavLink>
                            )}
                        </DropdownContent>}
                    </div>
                </NavLi>)}
            <HighlightSpan {...highlight}/>
        </MainNav>
    </Bar>
}

export default Navbar;